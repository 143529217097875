import React, { FC, useRef } from 'react';

import { Head, Header, Footer, Blog, Contact } from 'components';

const Index: FC = () => {
  const ref = useRef<HTMLElement | null>(null);

  const handleHeaderClick = (): void => {
    if (!ref.current) throw new Error('No `ref`');

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  return (
    <>
      <Head />
      <Header handleClick={handleHeaderClick} />
      <main ref={ref}>
        <Contact />
        <Blog />
      </main>
      <Footer />
    </>
  );
};

export default Index;
